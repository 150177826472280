import React from 'react';

import './ComingSoon.css';

const ComingSoon = () => {
    return (
            <div className="center-item">
                <h1 className="title-coming-soon between-space-soon">Coming</h1>
                <div className="soon-circle-highlight">
                    <h1 className="title-coming-soon">soon!</h1>
                </div>
            </div>
    )
}

export default ComingSoon;