import React from 'react';

import BackToHomepageRedirect from '../common/BackToHomepage/BackToHomepageRedirect';
import ComingSoon from '../common/ComingSoon/ComingSoon';
import './ResourcesPageContent.css';

const ResourcesPageContent = () => {
    return (
        <>
            <ComingSoon />
            <p className="subtitle-resources-content center-item">Resources section is still under construction.</p>
            <BackToHomepageRedirect />
        </>
    )
};

export default ResourcesPageContent;