import * as React from "react"

import ResourcesPageContent from "../components/ResourcesPage/ResourcesPageContent"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ResourcesPage = () => (
  <Layout>
    <Seo title="Resources" />
    <ResourcesPageContent />
  </Layout>
);

export default ResourcesPage;
